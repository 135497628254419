
body {
  background: #222129;
}

#main_section {
  background: #6a668a;
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
}

h1 {
  text-align: center;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-size: 64px;
}

h1, h2 {
  font-weight: bold;
  text-decoration: underline;
}

h2, p {
  padding-left: 5%;
  padding-right: 5%;
  font-family: Arial, Helvetica, sans-serif;
}

h2 {
  font-size: 36px;
}

p {
  font-size: 24px;
}

#media {
  text-align: center;
}

video, img {
  width: 90%;
  height: auto;
}